import React from 'react';
import seoPage from '../../content/seo/blog.yml';
import { graphql } from 'gatsby';
import { BlogQueryProps } from 'src/types';
import BlogLayout from 'src/layout/BlogLayout';

export interface BlogProps {
	data: {
		allMdx: {
			edges: {
				node: BlogQueryProps;
			}[];
		};
	};
}

const BlogPage: React.FC<BlogProps> = ({ data }) => {
	return <BlogLayout data={data.allMdx.edges.map(el => el.node)} seoPage={seoPage} />;
};

export const pageQuery = graphql`
	query BlogQuery {
		allMdx(
			filter: { fileAbsolutePath: { regex: "//blog//" } }
			sort: { fields: fields___date, order: DESC }
		) {
			edges {
				node {
					id
					frontmatter {
						meta_description
						author
						banner
						h1
					}
					fields {
						date
						slug
					}
				}
			}
		}
	}
`;

export default BlogPage;
